<template>
	<v-container>
		<material-card id="main-card" color="info" title="Просмотренные фильмы">
			<template v-slot:headerRight>
				<v-btn text @click="clearData"><v-icon>icon-trash</v-icon></v-btn>
			</template>
			<Progress :error="error" :loading="loading"></Progress>

			<v-flex xs12>
				<channel-list :items="items"></channel-list>
			</v-flex>
			<v-flex xs12 class="text-md-center">
				<scroll-pagination v-if="hasMorePage" :page="page" :loading="loading" @page="onScroll"></scroll-pagination>
			</v-flex>
		</material-card>
	</v-container>
</template>

<script>
import ChannelList from "../components/channel-list.vue";
import ScrollPagination from "../components/helper/scroll-pagination.vue";

export default {
	components: { ChannelList, ScrollPagination },
	props: {
		type: [String]
	},
	data() {
		return {
			loading: false,
			items: null,
			error: null,
			page: 1,
			hasMorePage: false,
		};
	},
	created() {
		var t = this;
		document.title = "Просмотренные фильмы";

		t.fetchData();
	},
	methods: {
		fetchData() {
			var t = this;
			t.error = null;
			t.loading = true;

			window.ajax("/api/watched-movies", { body: { page: t.page } }, function(
				data,
				error
			) {
				t.loading = false;
				if (!error) {
					t.hasMorePage = data.hasMore;
					if (t.page == 1) {
						t.items = data.list;
					} else {
						t.items = [...t.items, ...data.list];
					}
				} else {
					t.error = data;
				}
			});
		},
		onScroll(page) {
			this.page = page;
			this.fetchData();
		},
		clearData() {
			var t = this;
			t.error = null;
			t.loading = true;

			window.ajax("/api/clear-watched-movies", {}, function() {
				t.loading = false;
				t.items = [];
				t.page = 1;
			});
		},
	},
};
</script>
<style scoped>
</style>